export enum EPayBlockPayTypes {
  CASH = 'cash',
  INSTANT = 'instant',
  SKINS = 'skins',
}

export enum EPayBlockTypes {
  BLOGGER = 'blogger',
  COMMON = 'common',
}

export interface IPayBlockProps {
  currencySymbol: string;
  isChecked: boolean;
  isLoading?: boolean;
  isValidFields?: boolean;
  payType: EPayBlockPayTypes;
  sum: number;
  type: EPayBlockTypes;
}

export interface IPayBlockEmits {
  (e: 'start'): void;
  (e: 'update:isChecked', value: boolean): void;
}
