<script setup lang="ts">
import { promoButtonColorPreset } from './presets';
import { usePromoStore } from '~/features/payment/store/promo.store';
import { SizePresets, TypePresets } from '~/components/SharedKitButton/SharedKitButton.presets';
import { EPromoTypes } from '~/features/payment/types/promo/client.types';
import { useUserStore } from '~/store/user/user.store';
import { PaymentEvents } from '~/repository/amplitude/events/payment';

const promoStore = usePromoStore();
const userStore = useUserStore();
const { promoInfo, currentPromo, activeBloggerBonus, commonPromo, img, isLoading } = storeToRefs(promoStore);

const { t } = useI18n();

const title = computed(() => (currentPromo.value ? t('PaymentPage.promocodeActive') : t('PaymentPage.promocode')));

const promoMessage = computed(() => {
  if (currentPromo.value?.type === EPromoTypes.BLOGGER) {
    return t('PaymentPage.promoMessageBlogger', { username: userStore.user?.nickname });
  } else {
    return t('PaymentPage.promoMessage', { username: userStore.user?.nickname, percent: commonPromo.value });
  }
});

const resetPromo = () => {
  PaymentEvents.promoReset({
    'Promo Name': promoInfo.value.input,
    'Promo Type': currentPromo.value?.type as string,
  });

  promoStore.clearPromo();
};
</script>

<template>
  <div :class="['promo-field', { 'promo-field--blogger': currentPromo?.type === EPromoTypes.BLOGGER }]">
    <span class="promo-field__title">{{ title }}</span>
    <div class="promo-field__content">
      <hr class="promo-field__sep" />
      <template v-if="!currentPromo">
        <div class="promo-field__content-input">
          <SharedInputField
            v-model:value="promoInfo.input"
            class="promo-field__input"
            :placeholder="$t('PaymentPage.promocodePlaceholder')"
            :error="promoInfo.error"
          />
          <SharedKitButton
            :size-preset="SizePresets.M"
            :color-preset="promoButtonColorPreset"
            :type-preset="TypePresets.RECTANGLE"
            :text="$t('PaymentPage.usePromo')"
            :is-loading="isLoading"
            class="promo-field__button"
            @click="promoStore.handlePromoInput(promoInfo.input)"
          />
        </div>
      </template>
      <template v-else>
        <div :class="['promo-field__result', { 'promo-field__result--custom': img }]">
          <UiImage v-if="img" class="promo-field__result--image" :src="img" />
          <div v-if="img" class="promo-field__result--message">{{ promoMessage }}</div>
          <div class="promo-field__result__footer">
            <div class="promo-field__result__text">
              <span class="promo-field__result--percent">+{{ activeBloggerBonus?.percent || commonPromo }}%</span>
              <span>{{ $t('PaymentPage.toPay') }}</span>
            </div>
            <SharedKitButton
              :size-preset="SizePresets.M"
              :color-preset="promoButtonColorPreset"
              :type-preset="TypePresets.RECTANGLE"
              :text="$t('PaymentPage.useAnotherPromo')"
              class="promo-field__button"
              @click="resetPromo()"
            />
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<style src="./PromoField.scss" lang="scss" scoped />
