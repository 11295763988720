<template>
  <div :class="['mini-offer', rootClasses]" @click="handleClick">
    <div class="mini-offer__content">
      <div class="mini-offer__content-prices">
        <BaseCurrency class="mini-offer__content-prices-new" override-by-default>{{ priceData.new }}</BaseCurrency>
        <BaseCurrency class="mini-offer__content-prices-old" override-by-default>{{ priceData.old }}</BaseCurrency>
        <span class="mini-offer__content-prices-profit">{{ priceData.profit }}</span>
      </div>
      <span class="mini-offer__content-name">{{ name }}</span>
    </div>
    <div class="mini-offer__badge">
      <SharedKitProgressCounter v-if="progressShow" :variant="counterData" />
      <SharedKitLabel v-else-if="label" v-bind="label" />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { IMiniOfferProps, IMiniOfferEmits } from './MiniOffer.types';
import { useOfferData } from '~/features/secret-shop/composables/useOfferData';

const props = defineProps<IMiniOfferProps>();
const { price, restraints, label, isSelected } = toRefs(props);

const emit = defineEmits<IMiniOfferEmits>();

const rootClasses = computed(() => ({
  'mini-offer--selected': isSelected.value,
}));

const { priceData, counterData } = useOfferData({ price, restraints });

const progressShow = computed(() => {
  if (restraints.value.time) return true;

  const { current, max } = restraints.value.counter;
  const isMoreThanMinimum = current / max > 0.2;

  return !(label.value && isMoreThanMinimum);
});

const handleClick = () => {
  emit('select');
};
</script>

<style scoped lang="scss" src="./MiniOffer.scss"></style>
