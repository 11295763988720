<template>
  <div :class="['select-field', rootClasses]">
    <label v-if="label" class="select-field__label"> {{ label }} <span v-if="required">*</span> </label>
    <div class="select-field__content">
      <div class="select-field__content-select" @click.prevent="isOpened = !isOpened">
        <div class="select-field__content-select__main">
          <LazyUiImage v-if="value?.icon" :src="value.icon" :width="21" :height="15" />
          <span :class="['select-field__content-select__main-placeholder', placeholderClasses]">
            {{ selectedOptionLabel }}
          </span>
        </div>
        <LazySvgoSmallArrowDownIcon class="select-field__content-select__arrow" />
      </div>
      <ul ref="list" :class="['select-field__content-list', listClasses]">
        <li
          v-for="option in options"
          :key="option.label"
          class="select-field__content-list__item"
          @click="handleOptionClick(option)"
        >
          <div class="select-field__content-list__item-content">
            <LazyUiImage v-if="option?.icon" :src="option.icon" :width="21" :height="15" />
            <span> {{ option.label }} </span>
          </div>
          <LazySvgoCheckmarkIcon v-if="option.value === value?.value" class="select-field__content-list__item-check" />
        </li>
      </ul>
    </div>
    <span v-if="error" class="select-field__error">{{ error }}</span>
  </div>
</template>

<script setup lang="ts">
import type { ISelectFieldProps, ISelectFieldEmits, ISelectFieldOption } from './SelectField.types';

const props = defineProps<ISelectFieldProps>();
const emits = defineEmits<ISelectFieldEmits>();

const isOpened = ref(false);
const list = ref<HTMLElement>();

const rootClasses = computed(() => ({
  'select-field--error': props.error,
}));

const placeholderClasses = computed(() => ({
  'select-field__content-select__main-placeholder--empty': !props.value,
}));

const listClasses = computed(() => ({
  'select-field__content-list--visible': isOpened.value,
}));

const selectedOptionLabel = computed(() => {
  if (!props.value) return props.placeholder;

  const selectedOptionObject = props.options.find((option) => option.value === props.value!.value);
  return selectedOptionObject?.label ?? '';
});

onMounted(() => {
  if (!list.value) return;
  useClickOutside(list.value, () => (isOpened.value = false), false);
});

const handleOptionClick = (option: ISelectFieldOption) => {
  emits('update:value', option);
  isOpened.value = false;
};
</script>

<style scoped lang="scss" src="./SelectField.scss" />
