<template>
  <section class="bonus-card">
    <span class="bonus-card__title">{{ title }}</span>
    <div class="bonus-card__body">
      <div class="bonus-card__body-wrapper"></div>
      <div class="bonus-card__body-content">
        <div class="bonus-card__body-content__title">
          <BaseCurrency v-if="isRuble" class="bonus-card__body-content__title-text">
            {{ formattedAmount }}
          </BaseCurrency>
          <span v-else class="bonus-card__body-content__title-text">{{ formattedAmount }}</span>
          <LazySvgoPaymentBonusPercent v-if="!isRuble" class="bonus-card__body-content__title-icon" />
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import type { IBonusCardProps } from './BonusCard.types';
import { PaymentColors } from '~/features/payment/constants/colors.constants';

const {
  payment: { project },
} = useProjectSettings();

const { t } = useI18n();
const props = withDefaults(defineProps<IBonusCardProps>(), {
  colors: () => ({
    background: {
      colorStops: PaymentColors.PAYMENT_GRADIENT,
      toDirection: '140deg',
    },
    border: {
      colorStops: PaymentColors.PAYMENT_GRADIENT_LIGHTEN,
      toDirection: '140deg',
    },
  }),
});

const title = computed(() => {
  if (!props.isCompanyBonus) return t('PaymentPage.promoBonus');
  return t('PaymentPage.tdBonus', { project });
});

const formattedAmount = computed(() => `${props.withoutPlus ? '' : '+'}${props.amount}`);

const styleVariables = computed(() => ({
  background: GlobalUtils.CSS.getBackgroundColor(props.colors.background),
  border: GlobalUtils.CSS.getBackgroundColor(props.colors.border),
}));
</script>

<style scoped lang="scss">
.bonus-card {
  --bonus-card-background: v-bind('styleVariables.background');
  --bonus-card-border: v-bind('styleVariables.border');
}
</style>

<style scoped lang="scss" src="./BonusCard.scss" />
